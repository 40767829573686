import { getPartner, isActivePartner } from '@/libraries/api';

import { getI18nInstance } from '@/libraries/i18n';
import type { Composer } from 'vue-i18n';

export function translate(scope: string, key: string): string {
    const i18n = getI18nInstance();
    if (i18n) {
        const t = i18n.global.t as Composer<{}, {}, {}, true>['t'];
        const translated = t(scope + '.' + key);
        if (translated === scope + '.' + key) return scope + '.' + key;
        return translated;
    }
    return key;
}

export function formatPrice(
    price: number | string,
    decimals?: boolean,
    maxPrice?: number
): string {
    if (Number.isNaN(price)) return '€0';

    const parsedPrice = parseFloat('' + price);
    const formattedPrice = decimals
        ? parsedPrice.toFixed(2)
        : Math.floor(parsedPrice);

    let formattedString =
        '€\xa0' + formattedPrice.toString().replace(/(\d+)(\d{3})/, '$1.$2');

    if (maxPrice && parseFloat(<string>formattedPrice) === maxPrice) {
        formattedString += '+';
    }

    return formattedString;
}

export function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function formatAttribute(
    attribute: any,
    unit?: string,
    currency?: boolean
): string {
    let value = typeof attribute === 'object' ? attribute.value : attribute;
    value = currency ? formatPrice(value, true) : value;
    return value ? (unit ? value + ' ' + unit : value) : '-';
}

export function deepCopy<TValue>(obj: TValue): TValue {
    return JSON.parse(JSON.stringify(obj));
}

export function fuelIconByType(fuelType: string) {
    if (fuelType === 'hybrid' || fuelType === 'Híbrido') {
        return 'as-icon-hybrid';
    } else if (fuelType === 'diesel' || fuelType === 'Diesel') {
        return 'as-icon-diesel';
    } else if (fuelType === 'electric' || fuelType === 'Eléctrico') {
        return 'as-icon-electric';
    } else if (
        fuelType === 'petrol' ||
        fuelType === 'gasoline' ||
        fuelType === 'Gasolina'
    ) {
        return 'as-icon-gasoline';
    }

    return 'as-icon-minus';
}

export function formatTorque(
    engine_type: string,
    torque?: any,
    torqueEl?: any
): string[] {
    if (
        arguments.length === 3 &&
        torque !== undefined &&
        torqueEl !== undefined
    ) {
        if (engine_type === 'hybrid') {
            return [formatAttribute(torque), formatAttribute(torqueEl)];
        }
        if (engine_type === 'electric') {
            return [formatAttribute(torqueEl)];
        }
        if (engine_type === 'petrol' || engine_type === 'diesel') {
            return [formatAttribute(torque)];
        }
    }
    if (torque !== undefined || torqueEl !== undefined) {
        if (torque) {
            return [formatAttribute(torque)];
        }
        if (torqueEl) {
            return [formatAttribute(torqueEl)];
        }
    }
    return [];
}

export function formatStandardEquipment(standardEquip: any) {

    if (standardEquip) {
        if (standardEquip.code == 'Y' || standardEquip.code == 'S') {
            return 'standard';
        } else {
            const count = standardEquip?.options.count;
            if (count == 0) {
                return 'no_information';
            } else {
                return (
                    count +
                    ' ' +
                    determineIfOptionSingularOrPlural(count)
                );
            }

        }
    } else return 'no_data';
}

export function determineIfOptionSingularOrPlural(count: number) {
    if (count == 1) {
        return 'option';
    }
    if (count > 1) {
        return 'options';
    }
}

export async function getPartnerData(brand: string) {
    return await getPartner(brand);
}

export async function isPartner(brand: string) {
    const response = await isActivePartner(brand);
    if (response['status'] != null && brand) {
        return response['status'];
    }
}

export function formatEmissions(attributes: any[], unit?: string): string {
    for (const attribute of attributes) {
        const value =
            typeof attribute === 'object' ? attribute.value : attribute;
        if (value >= 0) {
            return unit ? value + ' ' + unit : value;
        }
    }
    return '-';
}
