import {
    determineIfOptionSingularOrPlural,
    formatAttribute,
    formatStandardEquipment,
    formatTorque,
    formatEmissions,
} from '@/helpers/utils';

import {
    ATTRIBUTE_SCHEMA_MAPPING,
    ATTRIBUTE_OPERATOR_MAPPING,
    ATTRIBUTE_VALUE_SCHEMA_MAPPING,
    ATTRIBUTE_CODE_SCHEMA_MAPPING,
    BODY_TYPE_CODE_TO_TRANSLATION_MAPPING,
} from './adapterMapping';

import type { Car, CarOption, Filters, Filter, ApiFilter } from '@/types';
import { translate } from '@/helpers/utils';
import { toInteger } from "lodash";

export const filtersAdapter = (filters: Filters) => {
    const apiFilters: { filters: object[]; sorts: string[] } = {
        filters: [],
        sorts: [],
    };

    for (const filter of Object.values(filters)) {
        if (!filter.isDefault()) {
            const currentApiFilters: ApiFilter[] = filter.toApiFilter();
            if (currentApiFilters != undefined && currentApiFilters.length != 0)
                currentApiFilters.forEach((apiFilter: ApiFilter) => {
                    apiFilters.filters.push(apiFilter);
                });
            const currentApiSorts: string[] = filter.toApiSort();
            if (currentApiSorts != undefined && currentApiSorts.length != 0)
                currentApiSorts.forEach((apiSort: string) => {
                    apiFilters.sorts.push(apiSort);
                });
        }
    }

    return apiFilters;
};

export const vehicleDataAdapter = (
    vehicleData: any
): Car => {
    const attributes = vehicleData.attributes;
    const standardEquipment = vehicleData.standardEquipment;

    const options: CarOption[] = (vehicleData.options ?? []).map((o: any) => ({
        id: o.id,
        code: o.code,
        type: o.type,
        retail_price: o.retail_price,
        option_name: o.option_name,
        option_text: o.option_text
            .split('\n')
            .filter(
                (item: string) => !['and', 'or'].includes(item.toLowerCase())
            )
            .map((item: string) => item.replace(/\[.*\]\s+/, '')),
        changes_attributes: o.changes_attributes ?? [],
        option_build: o.option_build.map((b: any) => ({
            type: b.type,
            retail_price: b.retail_price,
            condition: b.condition,
            rule: b.rule,
        })),
    }));

    let engine_type = '';
    if (
        ['standard', 'de série', 'série', 'standaard'].includes(
            vehicleData.attributes['51801']?.value
        )
    ) {
        engine_type = 'hybrid';
    } else if (
        ['electric', 'Eléctrico', 'électrique', 'elektrisch'].includes(
            vehicleData.attributes['8708']?.value
        )
    ) {
        engine_type = 'electric';
    } else if (
        ['diesel', 'Diesel'].includes(vehicleData.attributes['8708']?.value)
    ) {
        engine_type = 'diesel';
    } else if (
        ['hydrogen', 'hydrogène', 'waterstof'].includes(
            vehicleData.attributes['8708']?.value
        )
    ) {
        engine_type = 'hydrogen';
    } else {
        engine_type = 'gasoline';
    }

    const fuel_tank_capacities = [];
    if (attributes['8903']) {
        fuel_tank_capacities.push(
            formatAttribute(attributes['8903'], 'L')
        );
    }
    if (attributes['8312']) {
        fuel_tank_capacities.push(
            formatAttribute(attributes['8312'], 'kWh')
        );
    }
    const fuel_tank_capacity = fuel_tank_capacities.length
        ? fuel_tank_capacities.join(' / ')
        : '-';

    let engine_power = '-';
    if (attributes['15303']) {
        const kwh = formatAttribute(attributes['15303'], 'kW');
        const hp = formatAttribute(
            Math.floor(attributes['15303'].value * 1.34102),
            translate('unit', 'cv')
        );
        engine_power = kwh + ' / ' + hp;
    }

    const charging_scenarios = [];
    if (attributes['53402'] && attributes['53403']) {
        charging_scenarios.push({
            mode: attributes['53402'].value,
            duration: formatAttribute(attributes['53403'], 'h'),
        });
    }
    if (
        attributes['53402_1'] &&
        attributes['53403_1']
    ) {
        charging_scenarios.push({
            mode: attributes['53402_1'].value,
            duration: formatAttribute(attributes['53403_1'], 'h'),
        });
    }
    if (
        attributes['53402_2'] &&
        attributes['53403_2']
    ) {
        charging_scenarios.push({
            mode: attributes['53402_2'].value,
            duration: formatAttribute(attributes['53403_2'], 'h'),
        });
    }

    let range = '-';
    if (attributes['8303']) {
        range = formatAttribute(attributes['8303'], 'km');
    } else if (attributes['42015']) {
        range = formatAttribute(attributes['42015'], 'km');
    } else if (attributes['62903']) {
        range = formatAttribute(attributes['62903'], 'km');
    }

    let strictType = '-';
    if (attributes['603']) {
        const code = attributes['603'].code;
        const name = BODY_TYPE_CODE_TO_TRANSLATION_MAPPING.get(code);
        strictType = formatAttribute(name);
    }

    let transmission = '-';
    if (attributes['420602']) {
        transmission =
            ATTRIBUTE_CODE_SCHEMA_MAPPING.get(
                attributes['420602'].code as string
            ) ?? '-';
    }

    const torque = formatTorque(
        engine_type,
        attributes['15307'],
        attributes['15322']
    );

    const has_seat_options = vehicleData.has_seat_options;

    let apple_android_car = 'no_data';
    if (vehicleData.hasOwnProperty('standardEquipment')) {
        if (
            standardEquipment['59802'] ||
            standardEquipment['59803']
        ) {
            if (
                standardEquipment['59802']?.code == 'Y' &&
                standardEquipment['59803']?.code == 'Y'
            ) {
                apple_android_car = 'Apple CarPlay/Android Auto';
            } else if (standardEquipment['59802'].code == 'Y') {
                apple_android_car = 'Apple CarPlay';
            } else if (standardEquipment['59803'].code == 'Y') {
                apple_android_car = 'Android Auto';
            } else {
                apple_android_car = 'no_information';
            }
        }
    }

    function formatLeatherSeats(datapoint: any) {
        if (datapoint) {
            return (
                datapoint?.options.count +
                ' ' +
                determineIfOptionSingularOrPlural(datapoint?.options.count)
            );
        } else {
            return 'no_data';
        }
    }

    function formatFullStandardEquipmentValue(datapoint: any, type: any) {
        if (!datapoint) {
            return 'no_data';
        }
        const count = datapoint?.options?.count;
        if (datapoint.code == 'S') {
            return type.text + ': ' + type.value;
        } else {
            if (count == 0) {
                return 'no_information';
            } else {
                return (
                    (count ?? 0) +
                    ' ' +
                    determineIfOptionSingularOrPlural(count)
                );
            }
        }
    }

    let standard_equipment: any = '';
    if (vehicleData.hasOwnProperty('standardEquipment')) {
        standard_equipment = {
            anti_collision_system: formatStandardEquipment(
                standardEquipment['44101']
            ),
            automatic_emergency_braking: formatStandardEquipment(
                standardEquipment['44106']
            ),
            lane_departure_warning: formatStandardEquipment(
                standardEquipment['46201']
            ),
            blind_spot_warning: formatStandardEquipment(
                standardEquipment['41401']
            ),
            anti_theft_protection: formatStandardEquipment(
                standardEquipment['14901']
            ),
            rear_cross_traffic_collission_avoidance: formatStandardEquipment(
                standardEquipment['56301']
            ),
            parking_assistance: formatFullStandardEquipmentValue(
                standardEquipment['49401'],
                standardEquipment['49403']
            ),
            rearview_camera: formatStandardEquipment(
                standardEquipment['21504']
            ),
            parking_sensors: formatStandardEquipment(
                standardEquipment['66037']
            ),
            key_less_entry: formatStandardEquipment(
                standardEquipment['37503']
            ),

            electric_trunk: formatStandardEquipment(
                standardEquipment['4101']
            ),
            trailer_hitch: formatStandardEquipment(
                standardEquipment['1601']
            ),
            foldable_rear_seats: formatStandardEquipment(
                standardEquipment['704']
            ),
            apple_android_car,
            seats_material: formatLeatherSeats(
                standardEquipment['17402']
            ),
            LED_lights: formatStandardEquipment(
                standardEquipment['50201']
            ),
            head_up_display: formatStandardEquipment(
                standardEquipment['9003']
            ),
            panoramic_roof: formatStandardEquipment(
                standardEquipment['67201']
            ),
            autonomous_drive: formatFullStandardEquipmentValue(
                standardEquipment['60301'],
                attributes['60302'],
            ),
            smart_key: formatStandardEquipment(
                standardEquipment['37501']
            ),
            remote_services: formatStandardEquipment(
                standardEquipment['63901']
            ),
            luxury_trim: formatStandardEquipment(
                standardEquipment['11901']
            ),
            illuminated_entry_system: formatStandardEquipment(
                standardEquipment['5101']
            ),
            ambient_lighting: formatStandardEquipment(
                standardEquipment['62001']
            ),
            privacy_glass: formatStandardEquipment(
                standardEquipment['46801']
            )
        };
    }

    let energy_consumption_kWh_100km = '-';
    if (attributes['62803']) {
        // 62803 is in Wh/km so divide by 10
        energy_consumption_kWh_100km = formatAttribute(
            attributes['62803'].value / 10,
            'kWh/100km'
        );
    } else if (attributes['55404']) {
        energy_consumption_kWh_100km = formatAttribute(
            attributes['55404'],
            'kWh/100km'
        );
    }

    const car: Car = {
        id: toInteger(vehicleData.id),
        urlKey: vehicleData.urlKey,
        img: vehicleData.image[0] || '/img/placeholder-car.svg',
        imgArray: vehicleData.image,
        price: attributes['902']?.value || '-',
        name: attributes['129']?.value || '-',
        model: attributes['129']?.code || '-',
        modelYear: vehicleData.attributes['115']?.code.substring(0,4) || '-',
        brand: attributes['128']?.value || '-',
        version: attributes['131']?.value || '-',
        availablePackages: options.filter((o) => o.type === 'package').length,
        options: options.filter((o) => o.type === 'option'),
        packages: options.filter((o) => o.type === 'package'),
        colors: options.filter(
            (o) =>
                o.type === 'color' &&
                Object.keys(o.changes_attributes).includes('31102') // Get only car body colors
        ),
        traction: formatAttribute(attributes['6502']),
        fuelType: engine_type,
        seatsNumber: formatAttribute(attributes['702']),
        hasSeatOptions: has_seat_options,
        doorNumber: formatAttribute(attributes['605']),
        type: formatAttribute(attributes['603']),
        strictType,
        transmission,
        power_output: formatAttribute(
            Math.floor(attributes['15303']?.value * 1.34102),
            translate('unit', 'cv')
        ),
        charging_time: formatAttribute(attributes['8304']),
        quick_charging_time: formatAttribute(attributes['8305']),
        range,
        engine_type,
        engine_power,
        top_speed: formatAttribute(attributes['13502'], 'km/h'),
        acceleration: formatAttribute(
            attributes['13503'],
            's (0-100 km/h)'
        ),
        torque: torque,
        fuel_consumption: formatAttribute(
            attributes['42008'],
            'km/l'
        ),
        fuel_consumption_l_100km: attributes['42008']
            ? (100 / attributes['42008'].value).toFixed(1) +
            ' l/100km'
            : '-',
        energy_consumption: formatAttribute(
            attributes['55422'],
            'km/kWh'
        ),
        energy_consumption_kWh_100km,
        tax_rate: formatAttribute(
            attributes['3516'],
            'I.S.V.',
            true
        ),
        car_width: formatAttribute(attributes['5803'], 'mm'),
        car_height: formatAttribute(attributes['5804'], 'mm'),
        car_length: formatAttribute(attributes['5802'], 'mm'),
        fuel_tank_capacity,
        battery_capacity: formatAttribute(
            attributes['8312']
                ? attributes['8312']
                : attributes['8333'],
            'kWh'
        ),
        car_weight: formatAttribute(attributes['24102'], 'kg'),
        boot_space: formatAttribute(attributes['6002'], 'L'),
        charging_scenarios,
        tax_isv: attributes['3516']
            ? attributes['3516'].value
            : '-',
        tax_iva: attributes['3517']
            ? attributes['3517'].value
            : '-',
        tax_ecovalor: attributes['3518']
            ? formatAttribute(attributes['3518'], undefined, true)
            : '-',
        tax_iuc: attributes['3520']
            ? attributes['3520'].value
            : '-',
        emissions: formatEmissions(
            [
                attributes['62203'],
                attributes['62203_1'],
                attributes['62203_2'],
                attributes['62203_3'],
            ],
            'gCO2/km'
        ),
        hybridization_upgrade: formatAttribute(attributes['48602']),
        standard_equipment: standard_equipment,
    };
    return car;
};
