export const ATTRIBUTE_SCHEMA_MAPPING = new Map<string, string>([
    ['car-version', '131'],
    ['fuel-type', '8708'],
    ['car-brand', '128'],
    ['car-model', '129'],
    ['budget', '902'],
    ['car-type', '603'],
    ['seating-capacity', '702'],
    ['luggage-size', '6002'],
    ['hybrid-engine', '51801'],
]);

export const ATTRIBUTE_OPERATOR_MAPPING = new Map<string, string>([
    ['budget', 'between'],
    ['car-type', 'in'],
    ['seating-capacity', 'between'],
    ['luggage-size', '>='],
]);

export const ATTRIBUTE_VALUE_SCHEMA_MAPPING = new Map<string, string>([
    // Generic data types
    ['standard', 'S'],
    ['not available', '-'],

    // Fuel types
    ['electric', 'E'],
    ['diesel', 'D'],
    ['gasoline', 'P'],
    ['hydrogen', 'H'],

    // Body types
    ['sedan', 'SA,SH'],
    ['suv-crossover', 'CR,OD,CP,PU'],
    ['coupe', 'CO'],
    ['hatch-back', 'HA'],
    ['minivan', 'FW,MM,BU'],
    ['station-wagon', 'CC,ES'],
    ['city-car', 'MC,4C'],
    ['convertible', 'CA,TA'],

    // Car types
    ['seating-capacity:small', '2-4'],
    ['seating-capacity:compact', '3-5'],
    ['seating-capacity:midsize', '4-5'],
    ['seating-capacity:large', '5-7'],
    ['luggage-size:small', '200'],
    ['luggage-size:compact', '200'],
    ['luggage-size:midsize', '400'],
    ['luggage-size:large', '400'],
]);

export const ATTRIBUTE_CODE_SCHEMA_MAPPING = new Map<string, string>([
    ['A', 'automatic'],
    ['C', 'automatic'],
    ['I', 'automatic'],
    ['M', 'manual'],
    ['Q', 'manual'],
    ['S', 'manual'],
    ['T', 'manual'],
    ['U', 'manual'],
    ['V', 'manual'],
]);

export const BODY_TYPE_CODE_TO_TRANSLATION_MAPPING = new Map<string, string>([
    ['SA', 'car_type.sedan'],
    ['SH', 'car_type.sedan'],
    ['CR', 'car_type.suv--short'],
    ['OD', 'car_type.suv--short'],
    ['CO', 'car_type.coupe'],
    ['HA', 'car_type.hatch_back'],
    ['FW', 'car_type.minivan'],
    ['MM', 'car_type.minivan'],
    ['CC', 'car_type.station_wagon'],
    ['ES', 'car_type.station_wagon'],
    ['MC', 'car_type.city_car'],
    ['4C', 'car_type.city_car'],
    ['CA', 'car_type.convertible'],
    ['TA', 'car_type.convertible'],
    ['CP', 'car_type.suv--short'],
    ['PU', 'car_type.suv--short'],
    ['BU', 'car_type.minivan'],
]);

export const FOLDABLE_SEAT_CODE_TO_TRANSLATION_MAPPING = new Map<string, string>([
    ['4', 'standard'],
    ['A', 'standard'],
    ['F', 'not_available'],
    ['I', 'standard'],
    ['O', 'standard'],
    ['S', 'standard'],
]);
