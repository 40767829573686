<script setup lang="ts">
import { AppConfigInjectionKey } from '../libraries/config';
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { brandingConfig } from '@/libraries/brandingConfig';
const { t } = useI18n();

const config = inject(AppConfigInjectionKey);
const brandingState = config?.forced_settings?.branding || 'default';
const brandingConfigState = brandingConfig[brandingState];

const linkTarget =
    brandingState === 'produpress' || brandingState === 'produpress_fr'
        ? '_blank'
        : '_self';

const currentYear = new Date().getFullYear();
</script>
<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="footer__column">
                        <ul>
                            <li>
                                <a
                                    :href="brandingConfigState?.parent_link"
                                    :target="linkTarget"
                                    >{{ $t('footer.brand_name') }}</a
                                >
                            </li>
                            <li>
                                <span>&copy;{{ currentYear }} {{ $t('footer.copyright') }}</span>
                            </li>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_about
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.about') }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="footer__column">
                        <ul>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_privacy
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.privacy_policy') }}</a
                                >
                            </li>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_terms
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.terms_an_conditions') }}</a
                                >
                            </li>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_cookies
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.cookies') }}</a
                                >
                            </li>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_intellectual
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.intellectual_rights') }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="footer__column">
                        <ul>
                            <li>
                                <a
                                    :href="
                                        brandingConfigState?.parent_link_contact
                                    "
                                    :target="linkTarget"
                                    >{{ $t('footer.contact') }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
